<template>
  <section>

    <header>
        
        <router-link to="/">
          <img src="@/assets/images/rumblefish-logo.svg" alt="Rumblefish.co" class="main-logo" width="140" height="43"/>
        </router-link>

        <div class="main_nav">
          <Nav />
        </div>
        
        <!-- Mobile Btn -->
        <div class="nav_mob_btn" @click="mobileNav = !mobileNav">
          <img src="@/assets/images/menu_btn.svg" alt="Menu Button" />
        </div>

        <div class="bottom_line_decorator brand_gradient"></div>

    </header>

    <!-- Mobile Menu -->
    <transition name="fade">
      <div class="mobile_nav" v-if="mobileNav">
        <Nav />
      </div>
    </transition>
  
  </section>
</template>

<script>
import Nav from './Nav.vue'

export default {
  name: 'Header',
  components: {
    Nav,
  },
  data () {
    return {
      mobileNav : false
    }
  },
  watch: {
    '$route' () {
      this.mobileNav = false;
    }
  }
}
</script>

<style lang="scss">

  @import "../styles/variables";

  header {
    
    background: #fff;
    color: #000;
    padding: 12px 25px;
    position: relative;
    z-index: 500;

    @media screen and (min-width: 781px) {
      padding: 12px 40px;
    }

  }

  .main-logo {
    width: 140px;
    margin-right: 30px;
  }

  .mobile_nav {

    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100vh;
    z-index: 100;
    background: $highlight-color;
    color: #000;
    padding-top: 70px;
    box-sizing: border-box;

    ul {

      width: 100%;
      margin-top: 30px;

      li {

        text-align: center;
        font-size: 30px;
        font-weight: 900;
        padding: 15px 20px;
        color: #000;

        a, a:link, a:visited {
          color: #000;
        }

      }

    }

  }

  .nav_mob_btn {

    position: absolute;
    top: 23px;
    right: 20px;
    width: 31px;
    cursor: pointer;

    @media screen and (min-width: 781px) {
      display: none;
    }

    img {
      width: 100%;
    }

  }

  .main_nav {
    
    display: none;

    @media screen and (min-width: 781px) {

      display: block;
      position: absolute;
      width: 600px;
      right: 40px;
      top: 30px;
      text-align: right;

    }

    ul {
      padding: 0px;
      margin: 0px;

      li {
        list-style: none;
        display: inline-block;
        padding: 0px 15px;
        font-size: 14px;

        a, a:visited, a:link {
          color: #4d4d4d;
        }

        &:last-child {
            border-left: 1px solid;
            padding-right: 0px;
        }

      }
    }
  }

  .bottom_line_decorator {

    height: 4px;
    position: absolute;
    width: 100%;
    bottom: -4px;
    left: 0px;

  }

</style>
