<template>
  <div class="page">
      
      <section class="container">
          
          <Category :data="artists" :path="$route.path" :title="title" class="category_page" />

      </section>
      
  </div>
</template>

<script>

import Category from '../components/Category.vue'
import axios from 'axios'
import { useRoute } from 'vue-router'
import {computed} from 'vue'

export default {
  name: 'CategoryPage',
  components: {
    Category
  },
  data () {
    return {
      artists : null,
      title : null
    }
  },
  methods : {
    getCategory (path) {

      let cat = "artists"; // Default is artists
      this.title = "Artistas";

      if(this.$i18n.locale == "en") {
        this.title = "Artists";
      }

      if (path == "/en/concepts" || path == "/conceptos") {
        cat = "concepts";
        this.title = "Conceptos";
        if(this.$i18n.locale == "en") {
          this.title = "Concepts";
        }
      } // endif

      if(this.apiMode) {
        
        axios.get(this.apiURLBase+'/api/'+cat).then(response => {
          this.artists = response.data;
        });

      } else {

        if(cat == "artists") {
          this.artists = this.artistsData[this.$i18n.locale];
        } else {
          this.artists = this.conceptsData[this.$i18n.locale];
        } // endif  

      } // endif
      
    }
  },
  mounted () {

    const route = useRoute();
    const path = computed(() =>route.path).value
    this.getCategory(path);
    

    this.$watch(
      () => this.$route.path, (path) => {
        // react to route changes...
        this.getCategory(path);
        window.scrollTo(0, 0);
      }
    )
   
  }
}
</script>

<style scoped>

</style>
