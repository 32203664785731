<template>
    <section class="carousel" v-if="data">
      
      <agile :options="carouselOptions" ref="carousel">
        
        <div class="carousel__slide" v-for="item in data" :key="item.id">
            <router-link :to="$i18n.messages[$i18n.locale].home.artists.url+'/'+item.url">
              <img :src="'/storage/'+item.thumb" :alt="item.name" />
              <span>{{ item.name }}</span>
            </router-link>
        </div>

      </agile>

  </section>
</template>

<script>
import { VueAgile } from 'vue-agile'

export default {
  name: 'Carousel',
  components: {
    agile: VueAgile
  },
  data () {
    return {
      carouselOptions: {
        navButtons: true,
        dots: false,
        infinite: true,
        slidesToShow: 1,
        responsive: [
          {
            breakpoint: 721,
            settings: {
              slidesToShow: 3
            }
          }
        ]
      }
    }
  },
  props: {
    data: Array,
    path: String,
  },
  watch: {
    $route() {
      this.$refs.carousel.reload();
    }
  }
}
</script>

<style lang="scss">

    .carousel {
        width: 90%;
        position: relative;
        display: block;
        margin: 0px auto;

        @media screen and (min-width: 781px) {
          width: 100%;
        }

        &__slide {

          text-align: center;
          padding: 20px;
          width: 30%;

          img {
            width: 100%;
            margin-bottom: 20px;
          }

          span {
            text-transform: uppercase;
            color: #000;
            font-weight: 900;
            font-size: 20px;
          }

          a, a:link, a:visited {
            color: #000;
            text-decoration: none;
          }
        }

    }

    .agile__nav-button {

      position: absolute;
      top: 50%;
      background: #fff;
      width: 50px;
      height: 50px;
      margin-top: -30px;
      border: none;
      font-size: 32px;
      font-family: monospace;
      cursor: pointer;
      
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;

      &.agile__nav-button--next {
        right: -35px;
        @media screen and (min-width: 781px) {
          right: -60px;
        }
      }

      &.agile__nav-button--prev {
        left: -35px;
        @media screen and (min-width: 781px) {
          left: -60px;
        }
      }
    }

</style>
