<template>
    <div class="category">
        
        <h2 v-html="title"></h2>

        <ul class="category__gallery">

            <!-- Item -->
            <li v-for="item in data" :key="item.id" class="category__gallery__item">
                <router-link :to="path+'/'+item.url">

                    <!-- Photo -->
                    <img :src="'/storage/'+item.thumb" :alt="item.name" />

                    <!-- Hover Content -->
                    <div class="category__gallery__item__hover">
                        <p v-html="item.name"></p>
                    </div>
                    <!-- / Hover Content -->

                    <!-- Touch Devices Only Name -->
                    <p v-html="item.name" class="category__gallery__item__touch"></p>

                </router-link>
            </li>
            <!-- / Item -->

        </ul>

    </div>
</template>

<script>
export default {
  name: 'Category',
  props: {
    data: Object,    
    title : String,
    path : String
  }
}
</script>

<style lang="scss" scoped>

    @import "../styles/variables";

    .category {

        h2 {
            text-transform: uppercase;
            font-size: 40px;
            text-align: center;

            @media screen and (min-width: 781px) {
                font-size: 60px;
            }
        }

        &__subtitle {

            font-size: 24px;
            display: block;
            margin: 20px auto;
            width: 100%;
            max-width: 700px;
            text-align: center;

        }

        &__gallery {

            margin: 50px auto;
            display: flex;
            flex-wrap: wrap;
            padding: 0px;
            width: 100%;

            &__item {

                display: inline-block;
                margin: 10px;
                vertical-align: top;
                width: 43.5%;
                position: relative;
                cursor: pointer;

                @media screen and (min-width: 781px) {
                    width: 30%;
                }

                img {
                    width: 100%;
                }

                &__hover {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    left: 0px;
                    top: 0px;
                    background: rgba(0,0,0,0.8);
                    border: 3px solid $highlight-color;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    font-size: 35px;
                    text-align: center;
                    opacity: 0;
                    transition: opacity 0.6s ease;

                    @media (hover: hover) {
                        &:hover {
                            opacity: 1;
                        }
                    }
                }

                &__touch {
                    display: none;
                    text-align: center;
                }

                a, a:link, a:visited {
                    color: #fff;
                    text-decoration: none;
                    font-weight: 900;
                    text-transform: uppercase;
                }

            }
        }

        &.category_page {

            h2 {
                font-size: 50px;
                margin: 90px 0px;
                
                @media screen and (min-width: 781px) {
                    font-size: 80px;
                }

            }

            .category__subtitle {
                display: none;
            }
        }

    }

</style>
