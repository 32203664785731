<template>
    <nav>
        <ul>
            <li v-for="item in $i18n.messages[$i18n.locale].nav.nav" :key="item.id">
                <router-link :to="'/'+item.url">
                    {{ item.name }}
                </router-link>
            </li>
            <li class="flag">
                <!--<div class="locale-changer">
                    <select v-model="$i18n.locale">
                    <option v-for="locale in $i18n.availableLocales" :key="`locale-${locale}`" :value="locale">{{ locale }}</option>
                    </select>
                </div>-->
                <span v-for="locale in $i18n.availableLocales" :key="`locale-${locale}`">
                    <img :src="require('../assets/images/'+locale+'_flag.png')" :alt="locale" @click="$i18n.locale = locale" v-if="$i18n.locale!=locale" />
                </span>
            </li>
        </ul>
  </nav>
</template>

<script>


export default {
  name: 'Nav',
  methods : {  
      goToLocaleURL(lang) {

          var curPath = this.$route.path;
          var index = this.navData[this.lang.iso].nav.findIndex(p => p.url == curPath);
          let targetURL = "";

          if(index==-1) {
              console.log(this.navData[lang].nav[0])
              targetURL = this.navData[lang].nav[0].url; // Go Home
          } else {
              targetURL = this.navData[lang].nav[index].url; // Go to URL
          } // endif

          this.lang = { "iso" : lang, "path" : this.navData[lang].nav[0].url }
          this.$router.push(targetURL);
      }
  }
}
</script>

<style lang="scss" scoped>

    @import "../styles/variables";

    li {

        list-style-type: none;
        text-transform: uppercase;
        font-weight: 600;
        letter-spacing: 1px;
        
        a, a:visited, a:link {
            text-decoration: none;
        }

        &.flag {
            width: 38px;
            cursor: pointer;

            img {
                width: 100%;
                margin-bottom: -1px;
            }
        }

    }

    .router-link-active {

        font-weight: 900;
        color: $main-color !important;
        
    }

</style>
