<template>
  <div class="page contact">

      <section class="container">
        <h1 v-html="$i18n.messages[$i18n.locale].contact.title" class="page__title"></h1>
      </section>

      <section class="container flex_wrapper">

        <!-- Contact Info -->
        <div class="contact__info">

          <div class="contact__info__content" v-for="item in $i18n.messages[$i18n.locale].contact.contacts" :key="item">

              <p class="contact__info__content__name">{{ item.name }}</p>
              <p class="contact__info__content__title">{{ item.title }}</p>
              <p class="contact__info__content__email"><a :href="'mailto:'+item.email">{{ item.email }}</a></p>
              <p class="contact__info__content__location">{{ item.location }}</p>

          </div>

        </div>
        <!-- /Contact Info -->

        <!-- Contact Form -->
        <div class="contact__form">

          <h2>{{ $i18n.messages[$i18n.locale].contact.form_description }}</h2>

          <Form @submit="sendForm">

            <div class="contact__form__field_wrapper field50 left">
              <Field class="contact__form__field" type="text" name="firstname" :rules="isRequired" :placeholder="$i18n.messages[$i18n.locale].contact.fields.firstname" />
              <ErrorMessage name="firstname" />
            </div>

            <div class="contact__form__field_wrapper field50 right">
              <Field class="contact__form__field" type="text" name="lastname" :rules="isRequired" :placeholder="$i18n.messages[$i18n.locale].contact.fields.lastname" />
              <ErrorMessage name="lastname" />
            </div>

            <div class="contact__form__field_wrapper field50 left">
              <Field class="contact__form__field" type="text" name="email" :rules="isRequired" :placeholder="$i18n.messages[$i18n.locale].contact.fields.email" />
              <ErrorMessage name="email" />
            </div>

            <div class="contact__form__field_wrapper field50 right">
              <Field class="contact__form__field" name="telephone" :placeholder="$i18n.messages[$i18n.locale].contact.fields.tel" />
              <ErrorMessage name="telephone" />
            </div>

            <div class="contact__form__field_wrapper">
              <Field class="contact__form__field lastfield" name="message" as="textarea" :rules="isRequired" :placeholder="$i18n.messages[$i18n.locale].contact.fields.msg" />
              <ErrorMessage name="message" />
            </div>

            <button class="btn">{{ $i18n.messages[$i18n.locale].contact.submit_btn }}</button>
          </Form>

          <p class="contact__form__response" v-if="responseMsg">{{ responseMsg }}</p>

        </div>
        <!-- /Contact Form -->

      </section>
      
  </div>
</template>

<script>
import { Field, Form, ErrorMessage } from 'vee-validate';
import axios from "axios";

export default {
  name: 'Contact',
  data() {
    return {
      responseMsg : null
    }
  },
  components: {
    Field, 
    Form, 
    ErrorMessage
  },
  methods: {
    isRequired(value) {
      if (value && value.trim()) {
        return true;
      }

      return this.$i18n.messages[this.$i18n.locale].contact.error;
    },
    sendForm(values, { resetForm }) {

      const endpoint = "./php/send-mail.php";
      let success = this.$i18n.messages[this.$i18n.locale].contact.success;
      let error = this.$i18n.messages[this.$i18n.locale].contact.sendError;

      axios.post(endpoint, values).then((response) => {
        //console.log(response);
        if(response.data.success == true || response.data.success == "true") {
          this.responseMsg = success;
          resetForm();
        } else {
          this.responseMsg = error;
        } // endif
      })
      .catch((error) => {
        this.responseMsg = error;
        console.log(error);
      }); 
    }
  }
}
</script>

<style lang="scss" scoped>

  @import "../styles/variables";
  
  .flex_wrapper {

      @media screen and (min-width: 781px) {
        display: flex;
      }
  }

  .contact {

    &__form {

      width: 100%;
      margin-right: 10%;
      display: flex;
      flex-direction: column;

      @media screen and (min-width: 781px) {
        width: 55%;
      }

      h2 {
        width: 100%;
        text-transform: uppercase;
        margin: 20px 0px 50px;
        font-size: 22px;
      }

      &__field_wrapper {

        width: 100%;
        margin-bottom: 20px;
        display: inline-block;

        &.field50 {
          width: 47.5%;

          &.left { margin-right: 2.5%; }
          &.right { margin-left: 2.5%; }
        }

        span {
          display: block;
          margin: 5px 0px 0px;
          font-size: 11px;
          color: rgb(255, 0, 0);
          text-align: left;
        }

      }

      &__field {

        width: 100%;
        background: none;
        border: none;
        border-bottom: 2px solid #fff;
        color: #fff;
        padding: 3px;
        font-size: 18px;

        &.lastfield {
          height: 220px;
          margin-bottom: 30px;
        }

      }

      &__response {
        margin-top: 25px;
        text-align: center;
        font-size: 15px;
      }

    }
    
    
    &__info {

      width: 100%;
      margin-right: 5%;

      @media screen and (min-width: 781px) {
        width: 45%;
      }

      &__content {
        
        margin: 20px 0px;
        width: 100%;

        &__name {
          font-weight: 900;
          font-size: 22px;
          text-transform: uppercase;
        }

        &__title {
          font-weight: 600;
          text-transform: uppercase;
          color: $highlight-color;
        }

        &__email {

          a, a:link, a:visited {

            color: #fff;
            text-decoration: none;

          }

        }

      }

    }
    
  }

</style>
