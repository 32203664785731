<template>
  <div class="page about">

      <section class="container">
        
        <h1 v-html="$i18n.messages[$i18n.locale].about.title" class="page__title"></h1>

        <div class="page__content">
            <p v-for="item in $i18n.messages[$i18n.locale].about.content" :key="item" v-html="item"></p>
        </div>

        <div class="masonry-with-columns">
          <img src="@/assets/images/ea_fifa.jpg" alt="FIFA" />
          <img src="@/assets/images/about-1.jpg" alt="" />
          <img src="@/assets/images/about-3.jpg" alt="" />
          <img src="@/assets/images/about-2.jpg" alt="" />
        
          <img src="@/assets/images/about-4.jpg" alt="" />
          <img src="@/assets/images/about-5.jpg" alt="" />
        </div>

      </section>
      
  </div>
</template>

<script>

export default {
  name: 'About',
}
</script>

<style lang="scss">
@import "../styles/variables";

.masonry-with-columns {
  columns: 2 250px;
  column-gap: 2rem;
  width: 90%;
  margin: 40px 0px;

  img {
    width: 100%;
    margin: 20px 60px 20px 0px;
    border: 1px solid #fff;
  } 
  @for $i from 1 through 36 { 
    div:nth-child(#{$i}) {
      $h: (random(400) + 100) + px;
      height: $h;
      line-height: $h;
    }
  }
}

</style>
