<template>
    <div class="floating_contact">

        <!-- Form -->
        <div class="floating_contact__form_wrap" v-if="showFloatingForm">

            <h4>{{ $i18n.messages[$i18n.locale].profile.form.title }}</h4>
            <p>{{ $i18n.messages[$i18n.locale].profile.form.subtitle }}</p>

            <Form @submit="sendForm">

            <div class="floating_contact__form_wrap__field_wrapper field50 left">
              <Field class="floating_contact__form_wrap__field" type="text" name="firstname" :rules="isRequired" :placeholder="$i18n.messages[$i18n.locale].contact.fields.firstname" />
              <ErrorMessage name="firstname" />
            </div>

            <div class="floating_contact__form_wrap__field_wrapper field50 left">
              <Field class="floating_contact__form_wrap__field" type="text" name="email" :rules="validateEmail" :placeholder="$i18n.messages[$i18n.locale].contact.fields.email" />
              <ErrorMessage name="email" />
            </div>

            <div class="floating_contact__form_wrap__field_wrapper field50 right">
              <Field class="floating_contact__form_wrap__field" name="telephone" :placeholder="$i18n.messages[$i18n.locale].contact.fields.tel" />
              <ErrorMessage name="telephone" />
            </div>

            <button class="btn">{{ $i18n.messages[$i18n.locale].contact.submit_btn }}</button>
            <span class="cancel" @click="showFloatingForm = !showFloatingForm">{{ $i18n.messages[$i18n.locale].contact.cancel }}</span>

          </Form>
        </div>
        <!-- /Contact Form -->

        <div class="floating_contact__btn" @click="showFloatingForm=!showFloatingForm"  v-else>
            <img src="@/assets/images/email-icon.svg" alt="email"  />
        </div>

    </div>
</template>

<script>
import { Field, Form, ErrorMessage } from 'vee-validate';
import axios from "axios";

export default {
  name: 'FloatingContact',
  components: {
    Field, 
    Form, 
    ErrorMessage
  },
  data () {
      return {
          showFloatingForm : false,
      }
  },
  props: {
    profile : String
  },
  methods : {
    isRequired(value) {
      if (value && value.trim()) {
        return true;
      }
      return 'This field is required';
    },
    validateEmail(value) {
      // if the field is empty
      if (!value) {
        return 'This field is required';
      }
      // if the field is not a valid email
      const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
      if (!regex.test(value)) {
        return 'This field must be a valid email';
      }
      // All is good
      return true;
    },
    sendForm(values, { resetForm }) {

      const endpoint = "./php/send-mail.php";
      let success = this.$i18n.messages[this.$i18n.locale].contact.success;
      let error = this.$i18n.messages[this.$i18n.locale].contact.sendError;

      axios.post(endpoint, values).then((response) => {
        //console.log(response);
        if(response.data.success == true || response.data.success == "true") {
          this.responseMsg = success;
          resetForm();
        } else {
          this.responseMsg = error;
        } // endif
      })
      .catch((error) => {
        this.responseMsg = error;
        console.log(error);
      }); 
    }
  }
}
</script>

<style lang="scss" scoped>
  
  @import "../styles/variables";

  .floating_contact {

    text-align: center;

    &__btn {

        position: fixed;
        right: 10px;
        bottom: 10px;
        width: 50px;
        height: 50px;
        padding: 12px 8px;
        cursor: pointer;
        background: #fff;
        text-align: center;
        border: 2px solid $highlight-color;
        z-index: 20;

        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;

        @media screen and (min-width: 781px) {
          right: 40px;
          width: 70px;
          height: 70px;
          padding: 18px 12px;
        }

        img {
            width: 100%;
        }

    }

    &__form_wrap {

        position: fixed;
        bottom: 10px;
        right: 20px;
        background: #000;
        border: 3px solid $highlight-color;
        padding: 20px;
        width: 250px;
        z-index: 22;

        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        border-radius: 20px;

        @media screen and (min-width: 781px) {
          bottom: 20px;
          right: 40px;
        }

        h4 {
            text-transform: uppercase;
            font-weight: 900;
            text-align: center;
            letter-spacing: 1px;
        }

        p {
            text-align: center;
            font-size: 12px;
            margin: 10px auto 0px;
        }

        &__field {

            background: none;
            width: 100%;
            padding: 4px;
            border: none;
            border-bottom: 2px solid #fff;
            margin-top: 15px;
            font-size: 16px;
            color: #fff;
        }

        span {
          display: block;
          margin: 5px 0px 0px;
          font-size: 11px;
          color: rgb(255, 0, 0);
          text-align: left;
        }

    }
    
    .btn {
        margin-top: 16px;
    }

    .cancel {
      color: #fff;
      font-size: 14px;
      text-align: center;
      display: block;
      cursor: pointer;
      margin: 20px auto 0px;
    }

  }

</style>
