<template>
    <footer class="footer" :style="{ backgroundImage: 'url('+ require('../assets/images/party_bkg.jpg') + ')'}">
        
        <div class="footer__top_gradient"></div>

        <ul class="footer__social">
          <li>
              <a href="#" target="_blank">
                <img src="@/assets/images/facebook-icon-footer.svg" alt="Facebook" />
              </a>
          </li>
          <li>
              <a href="#" target="_blank">
                <img src="@/assets/images/instagram-icon-footer.svg" alt="Instagram" />
              </a>
          </li>
        </ul>

        <img src="@/assets/images/rumblefish_white_logo.svg" alt="Rumblefish.co" class="footer__logo" />

        <p class="footer__text" v-html="footerData[lang.iso].footnote"></p>

        <div class="footer__decorator brand_gradient"></div>

    </footer>
</template>

<script>
export default {
  name: 'Footer'
}
</script>

<style lang="scss" scoped>

  .footer {

    width: 100%;
    position: relative;
    background-size: cover;
    background-position: center 0px;
    text-align: center;
    padding: 80px 40px 40px;

    @media (min-width: 920px) { 
     background-position: center -90px;
    }

    &__top_gradient {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 80px;
      z-index: 10;
      background: rgb(0,0,0);
      background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%, rgba(0,0,0,0) 100%);
    }

    &__social {
      
      margin: 20px auto;
      padding: 0px;
      text-align: center;

      li {
        list-style-type: none;
        display: inline-block;
        margin: 0px 15px;
        width: 45px;

        img {
          width: 100%;
        }
      }
    }

    &__logo {
      width: 80%;
      max-width: 300px;
      display: block;
      margin: 30px auto;
    }

    &__text {
      font-size: 13px;
    }

    &__decorator {
      position: absolute;
      left: 0px;
      width: 100%;
      height: 5px;
      bottom: 0px;
    }

  }

</style>
